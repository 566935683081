.HeaderBlock_wrapper{
    width: 100%;
    height: max-content;
    grid-column: 1/ span 2;
    grid-row: 1/span 1;


    display: flex;
    flex-direction: column;

    transition-delay: 0s;


}

.NewScV_header{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: max-content;
    top: 0;
    left: 0;

}
.NewScV_header.active{
    grid-template-rows: max-content 1fr;
    height: 100vh;
}

.NewScV_header .click_area_nav{
    width: 100%;
    height: calc(100% + 20px);
    background-color: inherit;
    display: block;
    margin-top: -20px;

    z-index: 100;
}

.HeaderBlock_wrap{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(280px, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr);
    padding: 10px 10px 10px 20px;
    width: 100%;
    height: max-content;
min-height: 55px;
    align-items: center;
    grid-gap: 5px;



    z-index: 100;
    margin-bottom: -1px;
}

.back_mail{
    display: flex;
    flex-wrap: nowrap;
    width:max-content;
}
.HeaderBlock_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    text-align: left;
    grid-gap: 5px;
    text-decoration: none;

}
.HeaderBlock_item svg{
    height: 3.8vh;

}
.HeaderBlock_item  span{
    line-height: normal;
}
.cifra_logo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    height: max-content;

}
.cifra_logo_text > span{
    font-size: .8em;
}
.cifra_logo_text > span:nth-child(2){
    font-size: 1em;
    font-weight: 500;
}

.HeaderBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 100;
}


.Title_block_name{
    width: max-content;
    height: 100%;
    display: flex;
flex-direction: column;
    text-align: left;
padding: 0 10px;
    font-size: clamp(14px, 4vw, 20px);
    line-height: 22px;
    font-weight: 600;


}
.Contact_block{
   display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

}
.Contact_block_item{
flex-wrap: wrap;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    padding: 2px 0;
    text-decoration: none;

}
.Contact_block_item:first-child > svg{
    height: 20px;

}
.Contact_block_item:last-child > svg{
    height: 15px;

}
.akim_panel:hover,
.akim_panel:hover svg path,
.Contact_block_item:hover svg path,
.Contact_block_item:hover{
    color: #659365;
    fill: #659365;
}
.akim_panel{
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang_block{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 10px;
    padding: 5px;
}
.lang_block > span:last-child,
.lang_block > span:first-child{
    cursor: pointer;
}
.lang_btn_item.active,
.lang_block_color.active,
.lang_block > span:last-child:hover,
.lang_block > span:first-child:hover{

    color: #659365;
}

.home_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}


.home_icon_text{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: min-content;
}

.logo_masl{
    width: 38px;
    height:38px
}
