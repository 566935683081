.List_state_wrapper.mob_v {
    display: none;
}

/*.HeaderBlock_wrap{
    grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
grid-gap: 5px;
    overflow: hidden;
}

}*/
.home_icon,
.logo_masl,
.HeaderBlock_item > svg,
.HeaderBlock_item > img{
    width: auto;
    height: auto;
max-width: 100%;
    max-height: 50px;
    min-height: 100%;
    min-width: 50px;
}
.HeaderBlock_wrap { font-size: clamp(12px,1vw, 20px);}
.leaflet-control-attribution.leaflet-control{
    display: none;
}
@media screen and (max-width: 1340px){
    .Header_logo_text{
        width: min-content;
    }
}
@media screen and (max-width: 1185px){
    .home_icon_text,
    .Header_logo_text,
    .cifra_logo_text{
        display: none;
    }
    .HeaderBlock_wrap{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr min-content max-content;

    }
    .HeaderBlock_wrap a{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 800px){

    .HeaderBlock_wrap{
        grid-template-columns: 1fr 1fr 1fr   min-content min-content;

    }
    .HeaderBlock_wrap .st3,
    .HeaderBlock_wrap .st5,
    .HeaderBlock_wrap .st4{
        display: none;
    }
}
@media screen and (max-width: 605px){

    .HeaderBlock_wrap{
        grid-template-columns: 1fr    min-content min-content;

    }
    .back_mail,
    .HeaderBlock_wrap .st1,
    .HeaderBlock_wrap .st2{
        display: none;
    }


    .childPop_style_item{
        grid-template-columns: max-content  1fr;
        grid-template-rows: max-content max-content;

    }
    .childPop_style_item > span:last-child{
        grid-column: 1/ span 2;
    }
    .childPop_style_item > span{
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
        flex-direction: row;
    }
    .Tooltip_maslihat_card.ant-popconfirm .ant-popconfirm-description{
        padding: inherit;
    }
    .ant-popover-inner{
        max-width: 80vw;
    }
}
.rc-virtual-list-holder{

}
.ant-select-dropdown{
    top: 110px!important;
}
@media screen and (max-width: 445px){

.HeaderBlock_wrap{
    grid-template-columns: 1fr  max-content;
    grid-template-rows: max-content max-content;
    grid-gap: 0 5px;
    padding: 5px 5px 0 0;
}
    .Title_block_name{
        grid-row: 1/ span 2;
        grid-column: 1/ span 1;
    }
    .Contact_block{
        grid-row: 1/ span 1;
        grid-column: 2/ span 1;
    }



    .List_state_wrapper {
        display: none;
    }

    .List_state_wrapper.mob_v {
        height: max-content;
        padding: 0;
        display: flex;
        background: white;
    }
    .Maslihat_modal_wrap{
grid-gap: 5px;
        max-width: 270px;
      left: 10px;
        top: 45px;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: 60px 60px max-content 1fr 1fr;
        padding: 15px 15px 10px 10px;

    }
    .Maslihat_modal_img{
        grid-row: 1/ span 2;
        grid-column: 1/ span 1;
        width: 100%;
        height: auto;
    }
    .Maslihat_modal_fio{
        grid-row: 1/ span 1;
        grid-column: 2/ span 1;
        font-size: clamp(12px, 3vw, 14px);
    }
    .Maslihat_modal_post{
        grid-row: 2/ span 1;
        grid-column: 2/ span 1;
        font-size: clamp(12px, 3vw, 12px);
        padding: 0;
    }
    .mail_btn{
        grid-row: 3/ span 1;
        grid-column: 1/ span 2;
        font-size: clamp(12px, 3vw, 14px);
    }
    .Maslihat_modal_wrap .first_btn{
        grid-row: 4/ span 1;
        grid-column: 1/ span 2;
        width: calc(100% - 10px);
        font-size: clamp(12px, 5vw, 14px);
        padding: 5px 10px;
    }
    .Maslihat_modal_wrap .second_btn{
        grid-row: 5/ span 1;
        grid-column: 1/ span 2;
        width: calc(100% - 10px);
        font-size: clamp(12px, 5vw, 14px);
        padding: 5px 10px;
    }
}

