.Main_wrapper{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100% ;
    grid-template-rows: 1fr;
    font-family: Roboto,sans-serif;
    position: relative;
    background: #ffffff;
    overflow: hidden;


}

.Main_wrapper .ant-spin{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Main_control{
    position: relative;
    display: flex;
    width: max-content;
    min-width: 260px;
}



.List {
    border: 1px solid #d9dddd;
}


.map_element_items{
    z-index: 100;
    position: absolute;
    display: flex;
   left: 0;
    top: 5px;
}
.map_element_items.nav_show_true{
    left: 335px;
}
.List_state_wrapper {
    display: flex;
    flex-direction: row;

    position: absolute;
    top: 5px;
    left: 10px;
    padding: 5px 20px;
    overflow: hidden;
    z-index: 100;

    width: max-content;
    min-width: 270px;
    height:calc(100% - 25px);
    border-radius: 15px;
}
.List_state_wrapper h2{
    font-weight: 600;
}
.List_state_wrap{

    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    padding: 10px;
    overflow: auto;
background: white;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    border-radius: 10px;
    height: max-content;
    max-height: calc(100vh - 120px);
    min-width: 240px;



}
.List_state_wrap h2{
    align-self: center;
    padding-top: 10px;
}
.List_state{
    width: max-content;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: left;
    height: 40px;




}
.List_state.hover,
.List_state:hover{
    color: rgb(70, 125, 169);

  text-decoration: underline;

}
.List_state:active{
    color: rgb(9, 56, 94);

}
.Maslihat_modal_wrap{
    width: 320px;
    height:max-content;
    max-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 10px;
    padding: 20px;
    position: relative;
left: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);




}
.Maslihat_modal_wrap a{
    text-decoration: none;

    grid-gap: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.Maslihat_modal_wrap a > span{
    width: 100%;
}
.Maslihat_modal_img{
    height:100%;
max-height: 35vh;
    width:auto;
    align-self: center;

}
.Maslihat_modal_fio{
    color: #2B294F;
    font-weight: bold;
    font-size: 1.3em;
    line-height: 22px;
    text-align: center;
    align-self: center;
    width: 100%;
}
.Maslihat_modal_post{
    font-size: .9em;
    color: gray;

    padding: 0 40px;

    text-align: center;
    width: 100%;
    display: flex;


}
.tel_btn,
.mail_btn{
    text-align: center;
    width: 100%;
    display: flex;
}
.blank_btn{
    padding: 10px 25px;
    border-radius: 25px;
    background: #576CB9;
    align-self: center;
    color: white;
    text-decoration: none;
    min-width: 210px;
    cursor: pointer;
    width: 230px;
    max-width: 100%;
    font-size: clamp(8px, 3vw, 16px);
}
.blank_btn:hover{
    color: white;
}
.cross_btn_card{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}
 .Tooltip_maslihat_card.ant-popover {
     z-index: 10000;

 }
.Tooltip_maslihat_card .ant-tooltip-inner{
    color: black;
}
.Tooltip_maslihat_card_btn{
    align-self: center;
    padding: 5px 5px;
    cursor: pointer;
    border-radius:10px;
    box-shadow: 0px 0px 13px -4px rgba(34, 60, 80, 0.49);
}
/*
.Tooltip_maslihat_card .ant-popconfirm-buttons,
.Tooltip_maslihat_card .ant-popconfirm-message{
display: none;
}*/
.Tooltip_maslihat_card.ant-popconfirm .ant-popconfirm-buttons,
.Tooltip_maslihat_card.ant-popconfirm  .ant-popconfirm-message-icon{
    display: none;
}
.Tooltip_maslihat_card.ant-popconfirm .ant-popconfirm-message{
    padding: 0;
}
.childPop_style{
    width: 100%;
    height:max-content;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    max-width: 550px;

}
.childPop_style_item{
    width:100%;
    height:max-content;
    display: grid;
    grid-template-columns: max-content 150px 1fr;
grid-template-rows: max-content;
    justify-content: flex-start;
    grid-gap: 5px;

    align-items: flex-start;
}
.childPop_style_item .name_childPop{
    color: #353698;

    font-family: Roboto, sans-serif;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;

}
.childPop_style_item svg{
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Tooltip_maslihat_card.ant-popconfirm .ant-popconfirm-description{
 margin-inline-start: 0;
padding: 0 10px;
}

.tel_btn svg,
.mail_btn svg{
    width: 20px;
    height: 20px;
}

.Tooltip_maslihat_card .ant-popover-inner{
    max-width: 400px;
}
.ant-drawer  .Drawer_wrapper .ant-drawer-header{
padding: 5px;

}
.Drawer_wrapper{
    margin-bottom: 15%;
    max-width: 500px;
    width: 100%
}
.mob_bottom_card_wrap{
    display: grid;
    grid-template-columns: minmax(100px, 300px) 1fr;
    grid-template-rows:auto;

}
.Drawer_info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    text-align: center;
    padding: 5px 20px;

}
.ant-drawer .Drawer_wrapper .ant-drawer-body{
   padding: 5px;
}
.Drawer_wrapper .ant-drawer-bottom>.ant-drawer-content-wrapper{
    height: max-content;
}
.mail_btn{
    text-align: center;
    width: 100%;
}
.Drawer_wrapper .Maslihat_modal_img{
    align-self: auto;
}
