/*.markercluster-map {

    height: 100%;
    !* max-width: calc(100% - 200px); *!
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
}*/

.short_name_2line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Map_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.cluster-icon {
    background: #ffffff;
    border: 5px solid #146EB0;
    border-radius: 50%;
    color: #146EB0;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 33px;

    line-height: 16px;
}

.cluster-icon div {
    background: inherit;
    width: 100%;
    height: 100%;
    margin: auto;
}

.d3 {
    flex: 0 0 50%;
    background-color: #0a0a11;
    color: #fff;
}


.hexbin-hexagon {
    stroke: #000000;
    stroke-width: .5px;
}


.hexbin-container:hover .hexbin-hexagon {
    transition: 200ms;
    stroke: orange;
    stroke-width: 1px;
    stroke-opacity: 1;
}

.hexbin-tooltip {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid black;
    background-color: white;
}

/*----------------------*/
.clusterMarker_wrap.leaflet-marker-icon {

    background: #fff;
    border: 3px solid #005797;
    border-radius: 50%;
    color: #005797;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 15px;


}

.clusterMarker_wrap > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: auto;
    font-family: Inter, sans-serif;
    font-size: clamp(12px, 16px, 18px);
    font-style: normal;
    font-weight: 700;
    padding-top: 4px;
}

.leaflet-pane {
    z-index: 0 !important;
}

.leaflet-control-container > .leaflet-top.leaflet-left {
    top: 60px;
    right: 10px;
    left: inherit;
}

.leaflet-control-container > .leaflet-top.leaflet-right {
    top: 0px;
    right: 0;
    left: inherit;
}

.Home_btn_map {
    z-index: 10;
    display: flex;
    position: absolute;
    top: 146px;
    width: 30px;
    height: 30px;
    right: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.leaflet-control-geocoder-icon.search {
    width: 30px;
    height: 30px;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    width: 100%;
    height: 100%;
}

.Round_marker_icon{
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 100px;
    background: white;
    overflow: hidden;
    padding: 2px;

}
.Round_marker_icon span{
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;


    display: flex;


}
.Polygon_dist:hover{

    fill-opacity:0.5;
}
