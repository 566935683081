.search-input {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.search-button:hover {
    background-color: #0056b3;
}

.search-results {
    margin-top: 5px;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.result-item {
    padding: 5px;
    cursor: pointer;
}

.result-item:hover {
    background-color: #f1f1f1;
}

.leaflet-bar.leaflet-control-custom {
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
